import { PAGE_SIZE, ESTABLISHMENT_TOKEN } from "@/utils/constants";
import { numberToEn } from "@/utils/functions";
import axios from "axios";
import PaginationData from "./../../general/PaginationData";
import EmployeeImg from "@/assets/images/employees.svg";
import EmployeesFilter from "./EmployeesFilter";

export default class Employee {
  defaultImg = EmployeeImg;
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.image = "";
    this.employeeToken = "";
    this.employeeCode = "";
    this.employeeNameCurrent = "";
    this.employeeNameEn = "";
    this.employeeNameAr = "";
    this.employeeNameUnd = "";
    this.establishmentRoleToken = "";
    this.establishmentRoleNameCurrent = "";
    this.establishmentToken = ESTABLISHMENT_TOKEN;
    this.placeToken = "";
    this.establishmentNameCurrent = "";
    this.employeeSectorToken = "";
    this.sectorNameCurrent = "";
    this.employeeJobToken = "";
    this.jobNameCurrent = "";
    this.employeeAddressCurrent = "";
    this.employeeAddressEn = "";
    this.employeeAddressAr = "";
    this.employeeAddressUnd = "";
    this.lastAttendanceStatusToken = "";
    this.lastAttendanceStatusNameCurrent = "";
    this.lastAttendanceStatusNameEn = "";
    this.lastAttendanceStatusNameAr = "";
    this.lastAttendanceStatusNameUnd = "";
    this.lastMoveStatusToken = "";
    this.lastMoveStatusNameCurrent = "";
    this.lastMoveStatusNameAr = "";
    this.lastMoveStatusNameEn = "";
    this.lastMoveStatusNameUnd = "";
    this.employeeCountryToken = "";
    this.countryNameCurrent = "";
    this.employeeGovernateToken = "";
    this.governateNameCurrent = "";
    this.employeeCityToken = "";
    this.cityNameCurrent = "";
    this.employeeDistrictToken = "";
    this.districtNameCurrent = "";
    this.employeeQualificationToken = "";
    this.qualificationNameCurrent = "";
    this.employeeBankToken = "";
    this.bankNameCurrent = "";
    this.employeeSpecialSpecialtyToken = "";
    this.specialSpecialtyNameCurrent = "";
    this.employeeOfficeToken = "";
    this.officeNameCurrent = "";
    this.employeeBloodTypeToken = "";
    this.bloodTypeNameCurrent = "";
    this.employeeScientificDegreeToken = "";
    this.scientificDegreeNameCurrent = "";
    this.employeeCCWithCCName = "EG +20";
    this.employeeCountryCodeName = "EG";
    this.employeeCountryCode = "+20";
    this.employeePhone = "";
    this.employeePhoneWithCC = "";
    this.employeeOtherCCWithCCName = "EG +20";
    this.employeeOtherCountryCodeName = "EG";
    this.employeeOtherCountryCode = "+20";
    this.employeeOtherPhone = "";
    this.employeeOtherPhoneWithCC = "";
    this.emergencyPersonCCWithCCName = "EG +20";
    this.emergencyPersonCountryCodeName = "EG";
    this.emergencyPersonCountryCode = "+20";
    this.emergencyPersonPhone = "";
    this.emergencyPersonPhoneWithCC = "";
    this.employeeBirthdate = "";
    this.employeeUserName = "";
    this.employeeEmail = "";
    this.employeeDateTimeCreated = "";
    this.employeeNationalNumber = "";
    this.employeeNationalNumberExpireDate = "";
    this.employeeGenderToken = "";
    this.employeeGenderNameCurrent = "";
    this.employeeGenderNameAr = "";
    this.employeeGenderNameEn = "";
    this.employeeGenderNameUnd = "";
    this.employeeImagePath = "";
    this.employeeHourPrice = "";
    this.employeeDrivingLicenceNumber = "";
    this.employeeDrivingLicenceExpireDate = "";
    this.employeePassportNumber = "";
    this.employeePassportExpireDate = "";
    this.employeeMaritalToken = "";
    this.employeeMaritalNameCurrent = "";
    this.employeeMaritalNameAr = "";
    this.employeeMaritalNameEn = "";
    this.employeeMaritalNameUnd = "";
    this.employeeMilitaryToken = "";
    this.employeeMilitaryNameCurrent = "";
    this.employeeMilitaryNameAr = "";
    this.employeeMilitaryNameEn = "";
    this.employeeMilitaryNameUnd = "";
    this.employeeMaritalStatusNotes = "";
    this.employeeMilitaryStatusNotes = "";
    this.employeeContractStartDate = "";
    this.employeeContractEndDate = "";
    this.employeeCitizenShipTypeToken = "";
    this.employeeCitizenShipTypeNameCurrent = "";
    this.employeeCitizenShipTypeNameAr = "";
    this.employeeCitizenShipTypeNameEn = "";
    this.employeeCitizenShipTypeNameUnd = "";
    this.employeeResidencyNumber = "";
    this.employeeResidencyStartDate = "";
    this.employeeResidencyEndDate = "";
    this.employeeTypeToken = "";
    this.employeeTypeNameCurrent = "";
    this.employeeTypeNameAr = "";
    this.employeeTypeNameEn = "";
    this.employeeTypeNameUnd = "";
    this.employeePassword = "";
    this.employeeNotes = "";
    this.employeeStartWorkTime = "";
    this.employeeEndWorkTime = "";
    this.employeeActiveStatus = true;
    this.employeeArchiveStatus = "";
    this.employeeTotalTransactionPoints = "";
    this.employeeWalletBalance = "";
    this.bankAccountNumber = "";
    this.emergencyPersonNameCurrent = "";
    this.emergencyPersonNameEn = "";
    this.emergencyPersonNameAr = "";
    this.emergencyPersonNameUnd = "";
    this.emergencyPersonEmail = "";
    this.emergencyPersonRelativeRelationToken = "";
    this.emergencyPersonRelativeRelationNameCurrent = "";
    this.countingTypeToken = "";
    this.countingTypeNameCurrent = "";
    this.countingTypeNameEn = "";
    this.countingTypeNameAr = "";
    this.countingTypeNameUnd = "";
    this.familyNumber = "";
    this.employeeRelativeRelationToken = "";
    this.employeeRelativeRelationNameCurrent = "";
    this.employeeInsuranceCompanyToken = "";
    this.employeeInsuranceCompanyNameCurrent = "";
    this.employeeInsuranceNumber = "";
  }
  fillData(data) {
    this.employeeToken = data.employeeToken;
    this.employeeCode = data.employeeCode;
    this.employeeNameCurrent = data.employeeNameCurrent;
    this.employeeNameEn = data.employeeNameEn;
    this.employeeNameAr = data.employeeNameAr;
    this.employeeNameUnd = data.employeeNameUnd;
    this.establishmentRoleToken = data.establishmentRoleToken;
    this.establishmentRoleNameCurrent = data.establishmentRoleNameCurrent;
    this.establishmentToken = data.establishmentToken;
    this.placeToken = data.placeToken;
    this.establishmentNameCurrent = data.establishmentNameCurrent;
    this.employeeSectorToken = data.employeeSectorToken;
    this.sectorNameCurrent = data.sectorNameCurrent;
    this.employeeJobToken = data.employeeJobToken;
    this.jobNameCurrent = data.jobNameCurrent;
    this.employeeAddressCurrent = data.employeeAddressCurrent;
    this.employeeAddressEn = data.employeeAddressEn;
    this.employeeAddressAr = data.employeeAddressAr;
    this.employeeAddressUnd = data.employeeAddressUnd;
    this.lastAttendanceStatusToken = data.lastAttendanceStatusToken;
    this.lastAttendanceStatusNameCurrent = data.lastAttendanceStatusNameCurrent;
    this.lastAttendanceStatusNameEn = data.lastAttendanceStatusNameEn;
    this.lastAttendanceStatusNameAr = data.lastAttendanceStatusNameAr;
    this.lastAttendanceStatusNameUnd = data.lastAttendanceStatusNameUnd;
    this.lastMoveStatusToken = data.lastMoveStatusToken;
    this.lastMoveStatusNameCurrent = data.lastMoveStatusNameCurrent;
    this.lastMoveStatusNameAr = data.lastMoveStatusNameAr;
    this.lastMoveStatusNameEn = data.lastMoveStatusNameEn;
    this.lastMoveStatusNameUnd = data.lastMoveStatusNameUnd;
    this.employeeCountryToken = data.employeeCountryToken;
    this.countryNameCurrent = data.countryNameCurrent;
    this.employeeGovernateToken = data.employeeGovernateToken;
    this.governateNameCurrent = data.governateNameCurrent;
    this.employeeCityToken = data.employeeCityToken;
    this.cityNameCurrent = data.cityNameCurrent;
    this.employeeDistrictToken = data.employeeDistrictToken;
    this.districtNameCurrent = data.districtNameCurrent;
    this.employeeQualificationToken = data.employeeQualificationToken;
    this.qualificationNameCurrent = data.qualificationNameCurrent;
    this.employeeBankToken = data.employeeBankToken;
    this.bankNameCurrent = data.bankNameCurrent;
    this.employeeSpecialSpecialtyToken = data.employeeSpecialSpecialtyToken;
    this.specialSpecialtyNameCurrent = data.specialSpecialtyNameCurrent;
    this.employeeOfficeToken = data.employeeOfficeToken;
    this.officeNameCurrent = data.officeNameCurrent;
    this.employeeBloodTypeToken = data.employeeBloodTypeToken;
    this.bloodTypeNameCurrent = data.bloodTypeNameCurrent;
    this.employeeScientificDegreeToken = data.employeeScientificDegreeToken;
    this.scientificDegreeNameCurrent = data.scientificDegreeNameCurrent;
    this.employeeCCWithCCName =
      data.employeeCountryCodeName && data.employeeCountryCode
        ? `${data.employeeCountryCodeName} ${data.employeeCountryCode}`
        : "";
    this.employeeCountryCodeName = data.employeeCountryCodeName;
    this.employeeCountryCode = data.employeeCountryCode;
    this.employeePhone = data.employeePhone;
    this.employeePhoneWithCC = data.employeePhoneWithCC;
    this.emergencyPersonCCWithCCName =
      data.emergencyPersonCountryCodeName && data.emergencyPersonCountryCode
        ? `${data.emergencyPersonCountryCodeName} ${data.emergencyPersonCountryCode}`
        : "";
    this.emergencyPersonCountryCodeName = data.emergencyPersonCountryCodeName;
    this.emergencyPersonCountryCode = data.emergencyPersonCountryCode;
    this.emergencyPersonPhone = data.emergencyPersonPhone;
    this.emergencyPersonPhoneWithCC = data.emergencyPersonPhoneWithCC;
    this.employeeOtherCCWithCCName =
      data.employeeOtherCountryCodeName && data.employeeOtherCountryCode
        ? `${data.employeeOtherCountryCodeName} ${data.employeeOtherCountryCode}`
        : "";
    this.employeeOtherCountryCodeName = data.employeeOtherCountryCodeName;
    this.employeeOtherCountryCode = data.employeeOtherCountryCode;
    this.employeeOtherPhone = data.employeeOtherPhone;
    this.employeeOtherPhoneWithCC = data.employeeOtherPhoneWithCC;
    this.employeeBirthdate = data.employeeBirthdate;
    this.employeeUserName = data.employeeUserName;
    this.employeeEmail = data.employeeEmail;
    this.employeeDateTimeCreated = data.employeeDateTimeCreated;
    this.employeeNationalNumber = data.employeeNationalNumber;
    this.employeeNationalNumberExpireDate =
      data.employeeNationalNumberExpireDate;
    this.employeeGenderToken = data.employeeGenderToken;
    this.employeeGenderNameCurrent = data.employeeGenderNameCurrent;
    this.employeeGenderNameAr = data.employeeGenderNameAr;
    this.employeeGenderNameEn = data.employeeGenderNameEn;
    this.employeeGenderNameUnd = data.employeeGenderNameUnd;
    this.employeeImagePath = data.employeeImagePath;
    this.employeeHourPrice = data.employeeHourPrice;
    this.employeeDrivingLicenceNumber = data.employeeDrivingLicenceNumber;
    this.employeeDrivingLicenceExpireDate =
      data.employeeDrivingLicenceExpireDate;
    this.employeePassportNumber = data.employeePassportNumber;
    this.employeePassportExpireDate = data.employeePassportExpireDate;
    this.employeeMaritalToken = data.employeeMaritalToken;
    this.employeeMaritalNameCurrent = data.employeeMaritalNameCurrent;
    this.employeeMaritalNameAr = data.employeeMaritalNameAr;
    this.employeeMaritalNameEn = data.employeeMaritalNameEn;
    this.employeeMaritalNameUnd = data.employeeMaritalNameUnd;
    this.employeeMilitaryToken = data.employeeMilitaryToken;
    this.employeeMilitaryNameCurrent = data.employeeMilitaryNameCurrent;
    this.employeeMilitaryNameAr = data.employeeMilitaryNameAr;
    this.employeeMilitaryNameEn = data.employeeMilitaryNameEn;
    this.employeeMilitaryNameUnd = data.employeeMilitaryNameUnd;
    this.employeeMaritalStatusNotes = data.employeeMaritalStatusNotes;
    this.employeeMilitaryStatusNotes = data.employeeMilitaryStatusNotes;
    this.employeeContractStartDate = data.employeeContractStartDate;
    this.employeeContractEndDate = data.employeeContractEndDate;
    this.employeeCitizenShipTypeToken = data.employeeCitizenShipTypeToken;
    this.employeeCitizenShipTypeNameCurrent =
      data.employeeCitizenShipTypeNameCurrent;
    this.employeeCitizenShipTypeNameAr = data.employeeCitizenShipTypeNameAr;
    this.employeeCitizenShipTypeNameEn = data.employeeCitizenShipTypeNameEn;
    this.employeeCitizenShipTypeNameUnd = data.employeeCitizenShipTypeNameUnd;
    this.employeeResidencyNumber = data.employeeResidencyNumber;
    this.employeeResidencyStartDate = data.employeeResidencyStartDate;
    this.employeeResidencyEndDate = data.employeeResidencyEndDate;
    this.employeeTypeToken = data.employeeTypeToken;
    this.employeeTypeNameCurrent = data.employeeTypeNameCurrent;
    this.employeeTypeNameAr = data.employeeTypeNameAr;
    this.employeeTypeNameEn = data.employeeTypeNameEn;
    this.employeeTypeNameUnd = data.employeeTypeNameUnd;
    this.employeePassword = data.employeePassword;
    this.employeeNotes = data.employeeNotes;
    this.employeeStartWorkTime = data.employeeStartWorkTime;
    this.employeeEndWorkTime = data.employeeEndWorkTime;
    this.employeeActiveStatus = data.employeeActiveStatus;
    this.employeeArchiveStatus = data.employeeArchiveStatus;
    this.employeeTotalTransactionPoints = data.employeeTotalTransactionPoints;
    this.employeeWalletBalance = data.employeeWalletBalance;

    this.bankAccountNumber = data.bankAccountNumber;
    this.emergencyPersonNameCurrent = data.emergencyPersonNameCurrent;
    this.emergencyPersonNameEn = data.emergencyPersonNameEn;
    this.emergencyPersonNameAr = data.emergencyPersonNameAr;
    this.emergencyPersonNameUnd = data.emergencyPersonNameUnd;
    this.emergencyPersonCountryCodeName = data.emergencyPersonCountryCodeName;
    this.emergencyPersonCountryCode = data.emergencyPersonCountryCode;
    this.emergencyPersonPhone = data.emergencyPersonPhone;
    this.emergencyPersonPhoneWithCC = data.emergencyPersonPhoneWithCC;
    this.emergencyPersonEmail = data.emergencyPersonEmail;
    this.emergencyPersonRelativeRelationToken =
      data.emergencyPersonRelativeRelationToken;
    this.emergencyPersonRelativeRelationNameCurrent =
      data.emergencyPersonRelativeRelationNameCurrent;
    this.countingTypeToken = data.countingTypeToken;
    this.countingTypeNameCurrent = data.countingTypeNameCurrent;
    this.countingTypeNameEn = data.countingTypeNameEn;
    this.countingTypeNameAr = data.countingTypeNameAr;
    this.countingTypeNameUnd = data.countingTypeNameUnd;
    this.familyNumber = data.familyNumber;
    this.employeeRelativeRelationToken = data.employeeRelativeRelationToken;
    this.employeeRelativeRelationNameCurrent =
      data.employeeRelativeRelationNameCurrent;
    this.employeeInsuranceCompanyToken = data.employeeInsuranceCompanyToken;
    this.employeeInsuranceCompanyNameCurrent =
      data.employeeInsuranceCompanyNameCurrent;
    this.employeeInsuranceNumber = data.employeeInsuranceNumber;
  }

  async getEmployees(
    language,
    userAuthorizeToken,
    pagination = new PaginationData(),
    filterData = new EmployeesFilter()
  ) {
    try {
      let response = await axios.get(
        `/Employees/GetAllEmployees?language=${language}&userAuthorizeToken=${userAuthorizeToken}&page=${pagination.selfPage}&paginationStatus=true&pageSize=${PAGE_SIZE}&filterStatus=true&employeeTypeToken=${filterData.employeeTypeToken}&textSearch=${filterData.textSearch}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getDialogOfEmployees(
    language,
    userAuthorizeToken,
    filterData = new EmployeesFilter()
  ) {
    try {
      let response = await axios.get(
        `/Employees/GetDialogOfEmployees?language=${language}&userAuthorizeToken=${userAuthorizeToken}&filterStatus=true&employeeTypeToken=${filterData.employeeTypeToken}&textSearch=${filterData.textSearch}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getEmployeeDetails(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/Employees/GetEmployeeDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&employeeToken=${this.employeeToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async addOrUpdateEmployee(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      image: this.image,
      employeeToken: this.employeeToken,
      generalSpecialtyToken: this.generalSpecialtyToken,
      employeeSpecialSpecialtyToken: this.employeeSpecialSpecialtyToken,
      employeeBloodTypeToken: this.employeeBloodTypeToken,
      employeeNameEn: this.employeeNameEn,
      employeeNameAr: this.employeeNameAr,
      employeeCode: this.employeeCode,
      employeeCountryCodeName: this.employeeCountryCodeName,
      employeeCountryCode: this.employeeCountryCode,
      employeePhone: numberToEn(this.employeePhone),
      emergencyPersonCountryCodeName: this.emergencyPersonCountryCodeName,
      emergencyPersonCountryCode: this.emergencyPersonCountryCode,
      emergencyPersonPhone: numberToEn(this.emergencyPersonPhone),
      employeeOtherCountryCodeName: this.employeeOtherCountryCodeName,
      employeeOtherCountryCode: this.employeeOtherCountryCode,
      employeeOtherPhone: numberToEn(this.employeeOtherPhone),
      employeeUserName: this.employeeUserName,
      employeeEmail: this.employeeEmail,
      employeeBirthdate: this.employeeBirthdate,
      employeeAddressEn: this.employeeAddressEn,
      employeeAddressAr: this.employeeAddressAr,
      employeeGenderToken: this.employeeGenderToken,
      employeeQualificationToken: this.employeeQualificationToken,
      employeeSectorToken: this.employeeSectorToken,
      employeeJobToken: this.employeeJobToken,
      employeeNationalNumber: this.employeeNationalNumber,
      employeeNationalNumberExpireDate: this.employeeNationalNumberExpireDate,
      employeeDrivingLicenceNumber: this.employeeDrivingLicenceNumber,
      employeeDrivingLicenceExpireDate: this.employeeDrivingLicenceExpireDate,
      employeePassportNumber: this.employeePassportNumber,
      employeePassportExpireDate: this.employeePassportExpireDate,
      employeeMaritalToken: this.employeeMaritalToken,
      employeeMaritalStatusNotes: this.employeeMaritalStatusNotes,
      employeeMilitaryToken: this.employeeMilitaryToken,
      employeeMilitaryStatusNotes: this.employeeMilitaryStatusNotes,
      employeeTypeToken: this.employeeTypeToken,
      employeeNotes: this.employeeNotes,
      employeeActiveStatus: this.employeeActiveStatus,
      establishmentToken: this.establishmentToken,
      establishmentRoleToken: this.establishmentRoleToken,
      bankAccountNumber: this.bankAccountNumber,
      emergencyPersonNameCurrent: this.emergencyPersonNameCurrent,
      emergencyPersonNameEn: this.emergencyPersonNameEn,
      emergencyPersonNameAr: this.emergencyPersonNameAr,
      emergencyPersonNameUnd: this.emergencyPersonNameUnd,
      emergencyPersonEmail: this.emergencyPersonEmail,
      emergencyPersonRelativeRelationToken:
        this.emergencyPersonRelativeRelationToken,
      emergencyPersonRelativeRelationNameCurrent:
        this.emergencyPersonRelativeRelationNameCurrent,
      countingTypeToken: this.countingTypeToken,
      countingTypeNameCurrent: this.countingTypeNameCurrent,
      countingTypeNameEn: this.countingTypeNameEn,
      countingTypeNameAr: this.countingTypeNameAr,
      countingTypeNameUnd: this.countingTypeNameUnd,
      familyNumber: this.familyNumber,
      employeeRelativeRelationToken: this.employeeRelativeRelationToken,
      employeeRelativeRelationNameCurrent:
        this.employeeRelativeRelationNameCurrent,
      employeeInsuranceCompanyToken: this.employeeInsuranceCompanyToken,
      employeeInsuranceCompanyNameCurrent:
        this.employeeInsuranceCompanyNameCurrent,
      employeeInsuranceNumber: this.employeeInsuranceNumber,
    };

    if (this.image != "" && this.image != undefined) {
      var formData = new FormData();
      formData.append("image", this.image);
      formData.append("data", JSON.stringify(data));
    }

    try {
      let response = "";
      if (this.employeeToken == "" || this.employeeToken == undefined) {
        if (this.image != "" && this.image != undefined) {
          response = await axios.post(
            `/Employees/AddEmployeeWithImage`,
            formData
          );
        } else {
          response = await axios.post(
            `/Employees/AddEmployeeWithoutImage`,
            data
          );
        }
      } else {
        if (this.image != "" && this.image != undefined) {
          response = await axios.post(
            `/Employees/UpdateEmployeeWithImage`,
            formData
          );
        } else {
          response = await axios.post(
            `/Employees/UpdateEmployeeWithoutImage`,
            data
          );
        }
      }
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async archiveEmployee(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      employeeToken: this.employeeToken,
    };

    try {
      return await axios.post(`/Employees/ArchiveEmployee`, data);
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
